import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";
import keyboard from "../singletons/Keyboard";

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export default class SubNav {
	constructor(el) {
		this.el = el
		this.activeClass = "is-open";
		this.activeBodyClass = "sub-nav-is-open";

		this.isOpen = false;

		this.trigger = this.el.querySelector(".c-sub-nav__trigger");
		this.close = this.el.querySelector(".c-sub-nav__close");
		this.wrapper = this.el.querySelector(".c-sub-nav__wrapper");
		this.linksContainer = this.el.querySelector(".c-sub-nav__links");
		this.links = this.el.querySelectorAll(".c-sub-nav__link");
		this.focusableEls = this.wrapper.querySelectorAll(focusableSelector);
		this.body = document.body;

		this.events();
		this.initKeyboard();
	}

	events() {
		EventEmitter.on(EVENTS.CURRENT_CLICK_TARGET, (target) => this.onClickBody(target));

		this.trigger.addEventListener("click", () => this.onClickTrigger());
		this.close.addEventListener("click", () => this.disable());

		this.links.forEach(item => {
			item.addEventListener("mouseover", () => this.changeScheme(item));
		});

		if (this.linksContainer) {
			this.linksContainer.addEventListener("mouseout", () => this.removeSchemeClass());
		}
	}

	onClickBody(target) {
		if (target.closest(".c-sub-nav") != this.el) {
			this.disable();
		};
	}

	initKeyboard() {
		keyboard.listenOnKeyDown((e) => this.onKeydown(e));
	}

	onKeydown(e) {
		if (!this.isOpen) {
			return;
		}

		this.handleTabs(e);
		this.handleEsc(e);
	}

	handleTabs(e) {
		const firstFocusableElement = this.focusableEls[0];
		const lastFocusableElement = this.focusableEls[this.focusableEls.length - 1];

		let isTabPressed = e.key === "Tab";

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) { // if shift key pressed for shift + tab combination
			if (document.activeElement === firstFocusableElement) {
				lastFocusableElement.focus(); // add focus for the last focusable element
				e.preventDefault();
			}
		} else { // if tab key is pressed
			if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
				firstFocusableElement.focus(); // add focus for the first focusable element
				e.preventDefault();
			}
		}
	}

	handleEsc(e) {
		const isEsc = 27;

		if (e.keyCode === isEsc && this.el.matches(':focus-within')) {
			this.trigger.focus();
			this.disable();
		}
	}

	onClickTrigger() {
		this.toggle();
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}

		EventEmitter.emit(EVENTS.SUB_NAV_TOGGLE, this.isOpen);
	}

	enable() {
		if (this.el.classList.contains(this.activeClass)) {
			return;
		}

		this.el.classList.add(this.activeClass);
		this.body.classList.add(this.activeBodyClass);
		this.trigger.setAttribute("aria-expanded", true);
		this.wrapper.setAttribute("aria-hidden", false);
		this.close.setAttribute("tabindex", "0");
		this.enableLinks();

		this.isOpen = true;
	}

	disable() {
		if (!this.el.classList.contains(this.activeClass)) {
			return;
		}

		this.el.classList.remove(this.activeClass);
		this.body.classList.remove(this.activeBodyClass);
		this.trigger.setAttribute("aria-expanded", false);
		this.wrapper.setAttribute("aria-hidden", true);
		this.close.setAttribute("tabindex", "-1");
		this.disableLinks();

		this.isOpen = false;
	}

	changeScheme(link) {
		this.removeSchemeClass();

		this.subSchemeClass = link.dataset.scheme ?? "";

		if (this.subSchemeClass) {
			this.schemeClass = "scheme-sub" + this.subSchemeClass;

			this.wrapper.classList.add(this.schemeClass);
		}
	}

	removeSchemeClass() {
		let currentClass = this.getSchemeClass(this.wrapper);

		if (currentClass) {
			this.wrapper.classList.remove(currentClass);
		}
	}

	getSchemeClass(element) {
		// Convert the class list to an array and find the class that starts with 'scheme'
		const schemeClass = [...element.classList].find(cls => cls.startsWith('scheme'));

		// Return the found class or null if not found
		return schemeClass || null;
	}

	enableLinks() {
		this.links.forEach(item => {
			item.setAttribute("tabindex", "0");
		});
	}

	disableLinks() {
		this.links.forEach(item => {
			item.setAttribute("tabindex", "-1");
		});
	}
}
